import { H1, H2, H3, H4 } from "../../components/headline";
import { P } from "../../components/paragraph";
import { Link } from "../../components/link";
import { Section } from "../../components/section";
import { Dataset } from "../../components/dataset";
import { Process } from "../../components/process";
import { Video } from "../../components/video";
import { List, ListItem } from "../../components/list";
import { ProjectSwitch } from "../../components/projectswitch/ProjectSwitch";

import "./Share.css";
import intro from "./assets/share_intro.png";
import konkurrenz from "./assets/konkurrenz.jpg";
import konkurrenzx2 from "./assets/konkurrenzx2.jpg";
import test from "./assets/test.jpg";
import testx2 from "./assets/testx2.jpg";
import arrow from "./assets/arrow.svg";

import V1_1 from "./assets/V1_step1.jpg";
import V1_1x2 from "./assets/V1_step1x2.jpg";
import V1_2 from "./assets/V1_step2.jpg";
import V1_2x2 from "./assets/V1_step2x2.jpg";
import V1_3 from "./assets/V1_step3.jpg";
import V1_3x2 from "./assets/V1_step3x2.jpg";

import V2_1 from "./assets/V2_step1.jpg";
import V2_1x2 from "./assets/V2_step1x2.jpg";
import V2_2 from "./assets/V2_step2.jpg";
import V2_2x2 from "./assets/V2_step2x2.jpg";
import V2_3 from "./assets/V2_step3.jpg";
import V2_3x2 from "./assets/V2_step3x2.jpg";

import share_final from "./assets/share_final.mp4";
import shareMobile from "./assets/share_mobile.mp4";
import { Define } from "./assets/Define";
import { Ideate } from "./assets/Ideate";
import { Test } from "./assets/Test";
import { Prototype } from "./assets/Prototype";
import { Research } from "./assets/Research";

export const Share = () => {
  return (
    <>
      <Section reducedWidth>
        <H2>booky.io</H2>
        <H1>
          Neue exklusive <br />
          <span className="membership-headline--highlight">
            Teilen-Funktion
          </span>
        </H1>
        <P>
          Erarbeitung eines exklusiven Premium-Features, die Sammlungen privat
          oder öffentlich zu machen und sie per Link mit anderen teilen zu
          können.
        </P>
      </Section>
      <Section>
        <Dataset
          data={[
            {
              title: "Das Team:",
              text: "1 Frontend-Entwickler, 1 Backend-Entwickler, UX/UI Designerin (ich)",
            },
            { title: "Dauer:", text: "2 Wochen" },
            { title: "Tools:", text: "Miro, adobe XD" },
            {
              title: "Methoden & Techniken:",
              text: "Research, Ideation, Prototyping, Usability Testing, UX Writing",
            },
          ]}
        />
      </Section>
      <Section
        color="mint"
        intro
        direction="right"
        image={intro}
        retina={intro}
      >
        <H2>Kontext</H2>
        <P>
          <Link href="https://www.booky.io" target="_blank">
            booky.io
          </Link>{" "}
          ist eine 2014 erstellte Webseite, um Lesezeichen geräte- und
          browserübergreifend speichern und organisieren zu können.
        </P>
        <P>
          Nachdem ich die{" "}
          <Link to="/booky-membership">
            neue kostenpflichtige Support-Mitgliedschaft
          </Link>{" "}
          entwickelt habe, sollte als nächster Schritt die exklusive
          Teilen-Funktion entwickelt werden. Die Funktion ermöglicht es,
          Sammlungen öffentlich teilen zu können.
        </P>
      </Section>
      <Section>
        <H2>Designprozess</H2>
        <Process
          data={[
            { icon: <Research />, text: "Research" },
            {
              icon: <Define />,
              text: "Empathize & Define",
            },
            { icon: <Ideate />, text: "Ideate" },
            { icon: <Prototype />, text: "Prototype" },
            {
              icon: <Test />,
              text: "Test",
            },
          ]}
        />
      </Section>
      <Section reducedWidth>
        <H2>Research</H2>
        <P>
          Der typische Nutzer ist männlich, im Alter zwischen 45 und 55 Jahren
          und arbeitet in der IT, im Design oder Managementbereich. Damit ist er
          technisch affin und mit dem Web vertraut. Er hält sich
          durchschnittlich bis zu zehn Sekunden auf booky auf und nutzt dafür
          überwiegend den Laptop oder PC. Er verwendet booky sowohl privat, als
          auch beruflich und schätzt es besonders für seine
          Geräteunabhängigkeit.
        </P>
        <P>
          Für die Arbeit am Teilen-Feature bedeutet dies für mich, dass der Flow
          schnell und unkompliziert gestaltet werden muss. Dabei soll er
          auffällig genug sein, dass die Nutzer:innen ihn binnen weniger
          Sekunden entdecken. Für mein Research bedeutet das, dass ich mich auf
          das Aufdecken mentaler Modelle und Konkurrenzanalyse konzentriere, um
          bekannte Pattern zu verwenden.
        </P>
      </Section>
      <Section direction="left" image={konkurrenz} retina={konkurrenzx2}>
        <H3>Konkurrenzanalyse</H3>
        <P>
          Ich habe die Teilen-Funktion bei booky’s Konkurrenz ausprobiert und
          meine Gedanken und Gefühle dokumentiert. Ich habe Screenshots der
          einzelnen Steps aufgenommen und den Flow auf Miro nachgestellt.
        </P>
        <P>
          Danach habe ich ein UX-Review durchgeführt, also geschaut, ob es
          mögliche Usability-Probleme gibt. Das hat mir geholfen, meine Gefühle
          und Gedanken als Nutzerin aus der ersten Session in Kontext mit von
          mir identifizierten Usability-Problemen zu bringen.
        </P>
        <P>
          Als letzten Schritt habe ich mir große Seiten wie Google Docs, Adobe,
          Instagram und LinkedIn angesehen. Viele Menschen nutzen diese
          Webseiten täglich, weswegen sie an deren Patterns gewöhnt sind.
        </P>
        <H4>Key Learnings:</H4>
        <List>
          <ListItem>
            Grafisch verbindet man "teilen" mit einem Pfeil-, Sicherheitsnadel-
            oder Globus-Icon
          </ListItem>
          <ListItem>
            Die Information, was und mit wem geteilt wird, muss im Feature klar
            herausgestellt sein
          </ListItem>
          <ListItem>
            Zum Link gehört ein entsprechender "Kopieren"-Button, der den Link
            in die Zwischenablage kopiert
          </ListItem>
        </List>
      </Section>
      <Section reducedWidth>
        <H2>Empathise & Define</H2>
        <P>
          Unsere Nutzer:innen halten sich nur wenige Sekunden auf booky auf.
        </P>
        <P>
          Meine Lösung muss also einfach zu finden und schnell zu erledigen
          sein.
          <br /> Aus meinen gewonnenen Insights der Konkurrenzanalyse und
          mithilfe der Personas, die ich zuvor bereits entwickelt habe, lag mein
          Fokus auf dem User Flow.
        </P>
        <P>
          Wir wollen unseren Nutzer:innen ermöglichen, ihre Aufgabe so schnell
          wie möglich mit so wenig kognitiver Belastung wie nötig zu erledigen.
          Das bedeutet, dass sie keinen Gedanken an das “Wie” verschwenden
          sollen, sondern nur an das “Was”.
        </P>
      </Section>
      <Section color="anthrazit" direction="center">
        <H2>Die Challenge</H2>
        <P>
          booky-Nutzer:innen halten sich durchschnittlich nur bis zu zehn
          Sekunden pro Besuch auf booky auf. Die Teilen-Funktion muss somit auf
          einen Blick zu erfassen, schnell zu erreichen und durchzuführen sein.
        </P>
      </Section>
      <Section direction="left" reducedWidth>
        <H3>Business-Anforderungen</H3>
        <P>
          Im ersten Release soll es die Möglichkeit geben, seine Sammlung
          öffentlich per Link mit allen teilen zu können. Die Nutzer:innen, die
          den Link zugeschickt bekommen, müssen nicht bei booky registriert
          sein.
        </P>
        <P>
          Es soll einfach sein, zwischen dem privaten Modus und der öffentlichen
          Sammlung hin und her zu wechseln. Wird eine geteilte Sammlung wieder
          auf privat gestellt,verweisen Links, die mit anderen geteilt wurden,
          auf eine Error-Page.
        </P>
        <P>
          In Zukunft wird es auch weitere Möglichkeiten geben, seine Sammlungen
          zu teilen. Die zu entwickelnde Lösung muss also flexibel genug sein,
          auch das zu unterstützen.
        </P>
        <P>
          Außerdem handelt es sich um eine Premium-Funktion, ich musste schauen,
          wie ich dies an die Mitglieder:innen kommuniziere, die keine bezahlte
          Mitgliedschaft besitzen. Zuletzt sollte es dann noch eine
          Upsell-Möglichkeit bieten, die nicht mit der Firmenphilosophie
          kollidiert.
        </P>
      </Section>
      <Section reducedWidth>
        <H3>“How might we...?”</H3>
        <P>
          Um aus der Challenge und den Anforderungen konkrete Fragestellungen zu
          generieren, habe ich die 'How might we...? - Methode angewendet. Sie
          diente mir als Basis für meine ersten Scribbles.
        </P>
        <P bold>Ein paar der Fragen waren:</P>
        <List>
          <ListItem>
            Wie können wir die Aufmerksamkeit der Nutzer:innen bekommen?
          </ListItem>
          <ListItem>
            Wie können wir sicherstellen, dass Nutzer:innen mit dem neuen
            Feature interagieren?
          </ListItem>
          <ListItem>
            Wie können wir das Feature flexibel gestalten, sodass es
            nachträglich erweitert werden kann?
          </ListItem>
          <ListItem>
            Wie sieht die Funktion für reguläre Nutzer:innen aus?
          </ListItem>
        </List>
        <P>
          Mit diesen Fragestellungen bin ich dann in die Ideation-Phase
          gestartet.
        </P>
      </Section>
      <Section reducedWidth>
        <H2>Ideate</H2>
        <P>
          Nun ging es in die Ideenphase! Ich habe mir eine Stunde Zeit genommen,
          um alle möglichen Ideen auf ein weißes Blatt Papier zu bringen. Dazu
          habe ich auch den Gründer eingeladen.
        </P>
        <P>
          Gemeinsam haben wir versucht, “outside the box” zu denken und
          unterschiedliche Wege auszuprobieren. Unsere Ideen haben wir als
          einfache User Flows skizziert und uns gegenseitig vorgestellt. Danach
          haben wir die Pro’s und Con’s jeder Idee diskutiert und interessante
          Findings aus der Session aufgeschrieben.
        </P>
      </Section>
      <Section reducedWidth>
        <H2>Prototype</H2>
        <P>
          Die ersten Ideen habe ich auf Papier-Prototypen umgesetzt. Diese habe
          ich iterativ mit dem Gründer besprochen und Feedback eingearbeitet.
        </P>
        <P>
          Ich habe zwei unterschiedliche Varianten ausgearbeitet und für die
          Usability-Tests vorbereitet. Diese waren das Ergebnis aus der
          Konkurrenzanalyse und der Ideation-Session.
        </P>
      </Section>
      <Section>
        <H3>Variante A</H3>
        <div className="share__prototype">
          <img
            src={V1_1}
            srcset={(V1_1, V1_1x2)}
            className="share__prototype__step"
            alt=""
          />
          <img src={arrow} className="share__arrow" alt="" />
          <img
            src={V1_2}
            srcset={(V1_2, V1_2x2)}
            className="share__prototype__step"
            alt=""
          />
          <img src={arrow} className="share__arrow" alt="" />
          <img
            src={V1_3}
            srcset={(V1_3, V1_3x2)}
            className="share__prototype__step"
            alt=""
          />
        </div>
      </Section>
      <Section>
        <H3>Variante B</H3>
        <div className="share__prototype">
          <img
            src={V2_1}
            srcset={(V2_1, V2_1x2)}
            className="share__prototype__step"
            alt=""
          />
          <img src={arrow} className="share__arrow" alt="" />
          <img
            src={V2_2}
            srcset={(V2_2, V2_2x2)}
            className="share__prototype__step"
            alt=""
          />
          <img src={arrow} className="share__arrow" alt="" />
          <img
            src={V2_3}
            srcset={(V2_3, V2_3x2)}
            className="share__prototype__step"
            alt=""
          />
        </div>
      </Section>
      <Section noMargin>
        <H2>Test</H2>
        <P>Für den Test haben wir 4 Leute rekrutiert.</P>
      </Section>
      <Section direction="right" image={test} retina={testx2}>
        <H3>Moderierte Usability-Tests</H3>
        <P>
          Um ihr mentales Modell zu erfassen, habe ich zunächst wissen wollen,
          wo sie die Teilen-Funktion erwarten würden.
        </P>
        <P>
          Danach folgte die erste Aufgabe. Bei beiden Varianten bat ich die
          Nutzer:innen, eine Sammlung zu teilen.
        </P>
        <P>
          Nach Abschluss der Aufgaben habe ich nach der präferierten Variante
          gefragt und abgeklopft, was ihnen gut und nicht so gut gefallen hat.
        </P>
      </Section>
      <Section halfSpacing>
        <H3>Fazit</H3>
        <P>
          Es hat sich sehr gelohnt, zunächst das mentale Modell der
          Testteilnehmer:innen zu erfassen. Das hat uns Aufschluss darüber
          gegeben, wo das Feature erwartet wurde und wie wir es in der Sprache
          der Nutzer:innen nennen sollten.
        </P>
        <P>
          Gewonnen hat die Variante mit den wenigsten Clicks und den einfachsten
          Elementen. Informationen wurden nicht über mehrere Schritte hinweg
          gemerkt, daher hat die Variante gewonnen, die alle Informationen
          sofort zur Verfügung gestellt hat.
        </P>
        <P>
          Passend zu meinem Research über das Verhalten der booky Nutzer:innen,
          waren auch alle Nutzer:innen im Test sehr ergebnsiorientiert und haben
          die effizienteste Variante bevorzugt.
        </P>
      </Section>
      <Section>
        <H2>Learnings</H2>
        <List>
          <ListItem>
            Moderierte Tests helfen besonders, wenn man nicht nur die Usability
            testen, sondern auch die Nutzer:innen verstehen möchte
          </ListItem>
          <ListItem>
            Diskussionen und Brainstorming bringen unterschiedliche Ansichten
            zusammen und die besten Ergebnisse hervor
          </ListItem>
          <ListItem>
            Es hilft, wenn Button und Headline bei einem Pop-up identisch
            benannt sind. Dies sorgt für Konsistenz und Wiedererkennung.
            Unterschiedliche Benennungen haben zu Verwirrung geführt.
          </ListItem>
        </List>
      </Section>
      <Section color="anthrazit" direction="center">
        <H2>Das Ergebnis</H2>
        <Video src={share_final} srcMobile={shareMobile} />
      </Section>
      <Section>
        <H2>Mehr Details? Let's talk!</H2>
        <div className="home__contact">
          <svg
            className="home__contact-mail"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 11.016l8.016-5.016h-16.031zM20.016 18v-9.984l-8.016 4.969-8.016-4.969v9.984h16.031zM20.016 3.984q0.797 0 1.383 0.609t0.586 1.406v12q0 0.797-0.586 1.406t-1.383 0.609h-16.031q-0.797 0-1.383-0.609t-0.586-1.406v-12q0-0.797 0.586-1.406t1.383-0.609h16.031z"></path>
          </svg>
          <div>
            <P>
              Mehr zu meinen Projekten und Vorgehensweisen erzähle ich gerne
              persönlich! <br />
              Egal ob Video-Call, leger bei einem ersten Kaffee, oder ganz
              formell im Meetingraum: <br />
              Ich freue mich, von dir zu hören und dich kennenzulernen!
            </P>

            <Link className="home__email" href="mailto:hallo@samira-stein.de">
              <svg
                className="home__email-icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z"></path>
              </svg>
              hallo@samira-stein.de
            </Link>
          </div>
        </div>
      </Section>
      <ProjectSwitch previous="/booky-membership" next="/bootcamp" />
    </>
  );
};
