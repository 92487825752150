import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Section } from "../section";
import { Button } from "../button";

import "./ProjectSwitch.css";

export class ProjectSwitch extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    previous: PropTypes.string,
    next: PropTypes.string,
  };

  render() {
    const { className, previous, next } = this.props;
    return (
      <Section contentClassName="projectSwitch__wrapper">
        <Button
          to={previous}
          className={classNames(
            "projectSwitch__button",
            "button--outline",
            className
          )}
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="projectSwitch__button--previous"
          >
            <path d="M20.016 11.016v1.969h-12.188l5.578 5.625-1.406 1.406-8.016-8.016 8.016-8.016 1.406 1.406-5.578 5.625h12.188z"></path>
          </svg>
          Vorheriges Projekt
        </Button>
        <Button
          to={next}
          className={classNames(
            "projectSwitch__button",
            "button--outline",
            className
          )}
        >
          Nächstes Projekt
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="projectSwitch__button--next"
          >
            <path d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z"></path>
          </svg>
        </Button>
      </Section>
    );
  }
}
