import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Section.css";

export class Section extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
    reducedWidth: PropTypes.bool,
    direction: PropTypes.string,
    contentClassName: PropTypes.string,
    imageClassName: PropTypes.string,
    image: PropTypes.string,
    retina: PropTypes.string,
    small: PropTypes.string,
    intro: PropTypes.bool,
    noMargin: PropTypes.bool,
  };

  render() {
    const {
      children,
      className,
      color,
      reducedWidth,
      direction,
      contentClassName,
      imageClassName,
      image,
      retina,
      small,
      intro,
      noMargin,
    } = this.props;

    return (
      <section
        className={classNames(
          "section",
          color && `section--${color}`,
          noMargin && "section--noMargin",
          className
        )}
      >
        <div
          className={classNames(
            "section__content",
            reducedWidth && "section__content--reducedWidth",
            direction && `section__content--${direction}`,
            intro && "section__content--intro",
            image && "section__content--columns",
            contentClassName
          )}
        >
          {image || color ? (
            <div
              className={classNames(
                color && "section__content-wrapper",
                image && "section__content-wrapper--image"
              )}
            >
              {children}
            </div>
          ) : (
            children
          )}
          {image && (
            <picture
              className={classNames(
                "section__image",
                direction === "right" && "section__image--right",
                intro && "section__image--intro",
                imageClassName
              )}
            >
              <source media="(max-width: 600px)" srcSet={small} />
              <source
                media="(min-width: 601px)"
                srcSet={`${image} 1x, ${retina} 2x`}
              />
              <img
                className="section__image-fallback"
                loading="lazy"
                src={image}
                alt=""
              />
            </picture>
          )}
        </div>
      </section>
    );
  }
}
