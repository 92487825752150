export const Test = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className="bootcamp__icon"
  >
    <path d="M3 6h18v5.016h2.016v-5.016q0-0.844-0.586-1.43t-1.43-0.586h-18q-0.844 0-1.43 0.586t-0.586 1.43v12q0 0.844 0.586 1.43t1.43 0.586h9v-2.016h-9v-12zM15 12l-6-3.984v7.969zM22.688 18.422q0.094-0.469 0.047-0.844l1.078-0.844q0.141-0.141 0.047-0.328l-1.031-1.781q-0.094-0.188-0.328-0.141l-1.266 0.516q-0.328-0.234-0.75-0.422l-0.188-1.359q-0.047-0.234-0.281-0.234h-2.063q-0.188 0-0.234 0.234l-0.188 1.359q-0.234 0.094-0.422 0.188t-0.328 0.234l-1.313-0.516q-0.188-0.094-0.281 0.094l-1.031 1.828q-0.094 0.188 0.047 0.281l1.078 0.891q-0.047 0.469 0 0.844l-1.078 0.844q-0.188 0.141-0.047 0.328l1.031 1.781q0.094 0.188 0.281 0.141l1.266-0.516q0.375 0.234 0.75 0.422l0.234 1.359q0 0.188 0.234 0.188h2.063q0.234 0 0.234-0.188l0.234-1.359 0.375-0.188t0.328-0.234l1.313 0.516q0.188 0.047 0.281-0.141l1.031-1.781q0.141-0.188-0.047-0.328zM18.984 19.5q-0.609 0-1.055-0.445t-0.445-1.055 0.445-1.055 1.055-0.445q0.656 0 1.078 0.445t0.422 1.055-0.422 1.055-1.078 0.445z"></path>
  </svg>
);
