import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Headline.css";

export class Headline extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    type: PropTypes.string.isRequired,
    style: PropTypes.string,
    spaceBefore: PropTypes.bool,
    id: PropTypes.string,
  };

  render() {
    const {
      className,
      children,
      style,
      type: Type,
      spaceBefore,
      id,
    } = this.props;
    const headlineStyle = style ? style : Type;

    return (
      <Type
        id={id}
        className={classNames(
          "headline",
          `headline--${headlineStyle}`,
          spaceBefore && "headline--spaceBefore",
          className
        )}
      >
        {children}
      </Type>
    );
  }
}
