import { Headline } from "./Headline";

export const H1 = (props) => {
  return <Headline type="h1" {...props} />;
};

export const H2 = (props) => {
  return <Headline type="h2" {...props} />;
};

export const H3 = (props) => {
  return <Headline type="h3" {...props} />;
};

export const H4 = (props) => {
  return <Headline type="h4" {...props} />;
};
