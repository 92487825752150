import { H1, H2 } from "../../components/headline";
import { P } from "../../components/paragraph";
import { Link } from "../../components/link";
import { Section } from "../../components/section";

import "./Contact.css";

export const Contact = () => {
  return (
    <>
      <Section reducedWidth halfSpacing>
        <H1>
          Let's
          <br />
          <span className="contact__headline">get in touch!</span>
        </H1>
        <P>Schön, dass du hier gelandet bist!</P>
        <P>
          Schreib’ mir gerne eine E-Mail oder Nachricht auf XING oder LinkedIn.{" "}
          <br />
          Ich freue mich auf unseren Austausch!
        </P>
      </Section>
      <Section halfSpacing>
        <H2 style="h3">E-Mail</H2>
        <Link href="mailto:hallo@samira-stein.de" label="E-Mail">
          hallo@samira-stein.de
        </Link>
        <H2 style="h3" className="contact__social">
          Social media
        </H2>
        <Link
          className="contact__link--LI"
          href="https://www.linkedin.com/in/samira-stein-809755104/"
        >
          <svg
            className="contact__icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M21.75 0h-19.5c-1.237 0-2.25 1.013-2.25 2.25v19.5c0 1.237 1.013 2.25 2.25 2.25h19.5c1.237 0 2.25-1.013 2.25-2.25v-19.5c0-1.237-1.013-2.25-2.25-2.25zM9 19.5h-3v-10.5h3v10.5zM7.5 7.5c-0.83 0-1.5-0.67-1.5-1.5s0.67-1.5 1.5-1.5c0.83 0 1.5 0.67 1.5 1.5s-0.67 1.5-1.5 1.5zM19.5 19.5h-3v-6c0-0.83-0.67-1.5-1.5-1.5s-1.5 0.67-1.5 1.5v6h-3v-10.5h3v1.861c0.619-0.848 1.566-1.861 2.625-1.861 1.866 0 3.375 1.678 3.375 3.75v6.75z"></path>
          </svg>
        </Link>
        <Link
          className="contact__link--xing"
          href="https://www.xing.com/profile/Samira_Stein2/cv"
        >
          <svg
            className="contact__icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M21.75 0h-19.5c-1.237 0-2.25 1.013-2.25 2.25v19.5c0 1.237 1.013 2.25 2.25 2.25h19.5c1.237 0 2.25-1.013 2.25-2.25v-19.5c0-1.237-1.013-2.25-2.25-2.25zM7.327 15.609h-2.592c-0.155 0-0.272-0.070-0.338-0.178-0.070-0.112-0.070-0.253 0-0.398l2.756-4.866c0.005-0.005 0.005-0.009 0-0.014l-1.753-3.038c-0.070-0.145-0.084-0.286-0.014-0.398 0.066-0.108 0.197-0.164 0.356-0.164h2.597c0.398 0 0.595 0.258 0.722 0.488 0 0 1.772 3.094 1.786 3.113-0.103 0.188-2.803 4.955-2.803 4.955-0.141 0.244-0.328 0.502-0.717 0.502zM19.603 3.567l-5.747 10.162c-0.005 0.005-0.005 0.014 0 0.019l3.661 6.684c0.070 0.145 0.075 0.291 0.005 0.403-0.066 0.108-0.188 0.164-0.347 0.164h-2.592c-0.398 0-0.595-0.263-0.727-0.492 0 0-3.68-6.75-3.689-6.769 0.183-0.323 5.775-10.242 5.775-10.242 0.141-0.248 0.309-0.492 0.694-0.492h2.63c0.155 0 0.281 0.061 0.347 0.164 0.066 0.108 0.066 0.253-0.009 0.398z"></path>
          </svg>
        </Link>
      </Section>
    </>
  );
};
