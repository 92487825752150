import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { InView } from "react-intersection-observer";

import "./Video.css";
import frame from "./assets/frame.png";
import frameMobile from "./assets/frame_mobile.png";

export class Video extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    srcMobile: PropTypes.string,
    poster: PropTypes.string,
  };

  videoRef = React.createRef();

  componentDidMoun() {
    this.videoRef.current.load();
  }

  handleChange = (inView) => {
    if (inView) {
      this.videoRef.current.play();
    } else {
      this.videoRef.current.pause();
    }
  };

  render() {
    const { src, srcMobile, poster, className } = this.props;
    const isMobile = window.matchMedia("(max-width: 600px)").matches;

    return (
      <InView onChange={this.handleChange}>
        {({ ref }) => (
          <div className={classNames("video", className)} ref={ref}>
            <video
              ref={this.videoRef}
              type="video/mp4"
              muted
              loop
              src={isMobile && srcMobile ? srcMobile : src}
              poster={poster}
              className={classNames(
                "video__file",
                isMobile && srcMobile && "video__file--mobile"
              )}
              playsInline
            />
            <img
              src={isMobile && srcMobile ? frameMobile : frame}
              className="video__frame"
              alt=""
            />
          </div>
        )}
      </InView>
    );
  }
}
