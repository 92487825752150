import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

import "./Button.css";

export class Button extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    to: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
  };

  static defaultProps = {
    type: "primary",
  };

  render() {
    const { className, children, to, type, onClick, label } = this.props;
    const Element = to ? Link : "button";

    return (
      <Element
        to={to}
        onClick={onClick}
        className={classNames("button", `button--${type}`, className)}
        aria-label={label}
        title={label}
      >
        {children}
      </Element>
    );
  }
}
