import { H1 } from "../../components/headline";
import { Link } from "../../components/link";
import { P } from "../../components/paragraph";
import { Section } from "../../components/section";

export const Miss = () => {
  return (
    <Section>
      <H1>404</H1>
      <P>Hier gibt es leider nichts zu sehen!</P>
      <Link>Zur Startseite</Link>
    </Section>
  );
};
