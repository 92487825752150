import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Paragraph.css";

export class P extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    centered: PropTypes.bool,
    bold: PropTypes.bool,
  };

  render() {
    const { className, children, centered, bold } = this.props;

    return (
      <p
        className={classNames(
          "paragraph",
          centered && "paragraph--centered",
          bold && "paragraph--bold",
          className
        )}
      >
        {children}
      </p>
    );
  }
}
