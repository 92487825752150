import { H1, H2, H3 } from "../../components/headline";
import { P } from "../../components/paragraph";
import { Link } from "../../components/link";
import { Button } from "../../components/button";
import { Section } from "../../components/section";

import "./Home.css";
import sami from "./assets/Home.png";
import samix2 from "./assets/Homex2.png";

export const Home = () => {
  return (
    <>
      <Section
        direction="left"
        imageClassName="home__header-image"
        image={sami}
        retina={samix2}
      >
        <H1>
          Moin,
          <br />
          <span className="home__headline">I'm Sami!</span>
        </H1>
        <img
          loading="lazy"
          src={sami}
          srcSet={`${sami} 1x, ${samix2} 2x`}
          alt=""
          className="home__header-mobile-image"
        />
        <P>
          Ich bin eine UX Designerin aus Hamburg, die es sich zur Aufgabe
          gemacht hat, das Web inklusiver und simpler zu gestalten.
        </P>
        <P>
          Mit mehr als 7 Jahren Erfahrung in der Tech-Branche und
          Softwareentwicklung, stelle ich mich nun der Herausforderung,
          Technologie mit Usability und Design zu verbinden. Mit dem User im
          Fokus und Business in Mind.
        </P>
        <H2 style="h3" className="home__explore">
          Was möchtest du als Erstes entdecken?
        </H2>
        <Button type="primary" to="/#work" className="home__button">
          Die Projekte
        </Button>
        <Button type="secondary" to="/about" className="home__button">
          Die Person
        </Button>
      </Section>
      <div id="work">
        <Section
          color="blue"
          direction="right"
          noMargin
          className="home__supporter"
        >
          <H2 style="h3">booky.io</H2>
          <H3 style="h2">Neue Mitgliedschaft, Bezahl- und Kündigungsprozess</H3>
          <P>
            Entwicklung einer neuen kostenpflichtigen Mitgliedschaft und dem
            dazugehörigen Bezahl- und Kündigungsprozesses, sowie Aufbau der
            visuellen Marke booky.io.
          </P>
          <Button type="outline" to="/booky-membership">
            Zum Projekt
          </Button>
        </Section>
      </div>
      <Section color="mint" direction="left" noMargin className="home__share">
        <H2 style="h3">booky.io</H2>
        <H3 style="h2">Teilen-Funktion</H3>
        <P>
          Erarbeitung des exklusiven Premium-Features, Sammlungen öffentlich
          teilen zu können.
        </P>
        <Button type="outline" to="/booky-share">
          Zum Projekt
        </Button>
      </Section>
      <Section color="green" direction="right" className="home__pizza">
        <H2 style="h3">
          Luigis Pizza
          <span className="home__feature-subtitle"> - Case study</span>
        </H2>
        <H3 style="h2">Entwicklung eines neuen Pizza-Lieferservices</H3>
        <P>
          Case study zu einer neuen Pizza-Lieferservice-App mit Fokus auf User
          Research und Low-Fidelity prototyping.
        </P>
        <Button type="outline" to="/bootcamp">
          Zum Projekt
        </Button>
      </Section>
      <Section>
        <H2>Mehr Details? Let's talk!</H2>
        <div className="home__contact">
          <svg
            className="home__contact-mail"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 11.016l8.016-5.016h-16.031zM20.016 18v-9.984l-8.016 4.969-8.016-4.969v9.984h16.031zM20.016 3.984q0.797 0 1.383 0.609t0.586 1.406v12q0 0.797-0.586 1.406t-1.383 0.609h-16.031q-0.797 0-1.383-0.609t-0.586-1.406v-12q0-0.797 0.586-1.406t1.383-0.609h16.031z"></path>
          </svg>
          <div>
            <P>
              Mehr zu meinen Projekten und Vorgehensweisen erzähle ich gerne
              persönlich! <br />
              Egal ob Video-Call, leger bei einem ersten Kaffee, oder ganz
              formell im Meetingraum: <br /> Ich freue mich, von dir zu hören
              und dich kennenzulernen!
            </P>

            <Link className="home__email" href="mailto:hallo@samira-stein.de">
              <svg
                className="home__email-icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z"></path>
              </svg>
              hallo@samira-stein.de
            </Link>
          </div>
        </div>
      </Section>
    </>
  );
};
