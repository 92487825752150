import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Process.css";

export class Process extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
  };

  render() {
    const { className, data } = this.props;

    return (
      <ol className={classNames("process", className)}>
        {data.map(({ icon, text }) => (
          <li key={text} className="process__item">
            <div className="process__icon">{icon}</div>
            {text}
          </li>
        ))}
      </ol>
    );
  }
}
