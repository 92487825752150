export const Prototype = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className="bootcamp__icon"
  >
    <path d="M16.219 11.531l1.594-1.594-3.75-3.75-1.594 1.594-4.125-4.172q-0.563-0.563-1.406-0.563t-1.406 0.563l-1.922 1.922q-0.563 0.563-0.563 1.406t0.563 1.406l4.125 4.125-4.734 4.781v3.75h3.75l4.781-4.781 4.125 4.172q0.469 0.469 1.008 0.539t1.031-0.094 0.773-0.445l1.922-1.922q0.563-0.563 0.563-1.406t-0.563-1.406zM9.188 11.063l-4.125-4.125 1.875-1.875 1.266 1.266-1.172 1.172 1.406 1.406 1.172-1.172 1.453 1.453zM17.063 18.938l-4.125-4.125 1.875-1.875 1.453 1.453-1.172 1.172 1.406 1.406 1.172-1.172 1.266 1.266zM20.719 7.031q0.281-0.281 0.281-0.703t-0.281-0.703l-2.344-2.344q-0.375-0.328-0.797-0.258t-0.609 0.258l-1.828 1.828 3.75 3.75z"></path>
  </svg>
);
