import { H1, H2, H3, H4 } from "../../components/headline";
import { P } from "../../components/paragraph";
import { Section } from "../../components/section";
import { Link } from "../../components/link";

import "./About.css";
import sami from "./assets/sami.png";
import samix2 from "./assets/samix2.png";
import lebenslauf from "./assets/Resumee.pdf";

export const About = () => {
  return (
    <>
      <Section
        image={sami}
        retina={samix2}
        imageClassName="about__header-image"
      >
        <H1>
          <span className="home__headline">Designerin</span>
          <br />& woman in tech
        </H1>
        <img
          loading="lazy"
          src={sami}
          srcSet={`${sami} 1x, ${samix2} 2x`}
          alt=""
          className="about__header-mobile-image"
        />
        <P>
          Mit mehr als 7 Jahren Erfahrung in der Tech-Branche und
          Softwareentwicklung habe ich meine Leidenschaft für Technologie, User
          Experience und simples Design entdeckt. Ich brenne dafür, das Web für
          jeden Menschen zugänglicher und einfacher zu gestalten.
        </P>
        <P>
          Als UX-Designerin fokussiere ich mich besonders auf die Verbindung von
          Emotionen mit Technologie, einer einfachen Usability und inklusivem
          Design. Ich möchte die Probleme der Nutzer:innen verstehen und die
          passendste Lösung finden. Dies mit den Business-Goals zu verbinden,
          ist mein Auftrag.
        </P>
        <H4>Und neben der Arbeit am Laptop...</H4>
        <P>
          ...reise ich gern viel, nehme an Konferenzen teil, probiere neue Dinge
          aus und nehme das Beste aus diversen Kulturen für mich mit. Ich finde
          es wichtig, immer neugierig zu bleiben. Diese Neugierde treibt mich
          sowohl privat als auch im Job an.
        </P>
        <P>
          Ich liebe guten Kaffee, betreibe Kraftsport, höre Podcasts und ab und
          zu findet man mich auch auf Mittelalterfestivals. Ich freue mich auf
          unseren ersten gemeinsamen Kaffee in der Officeküche! ☕️
        </P>
        <H4>Noch ein paar mehr Info's zu mir?</H4>
        <P>
          Downloade mein Résumé:{" "}
          <Link href={lebenslauf} label="Download Résumé" target="_blank">
            hier!
          </Link>
        </P>
      </Section>
      <Section contentClassName="about__skills">
        <div>
          <H2 className="about__skill-headline">{"Skillset"}</H2>
          <span className="about__tag">{"Sketching"}</span>
          <span className="about__tag">{"UX Prototyping"}</span>
          <span className="about__tag">{"Usability Testing"}</span>
          <span className="about__tag">{"A/B Testing"}</span>
          <span className="about__tag">{"Accessibility"}</span>
          <span className="about__tag">{"Prozessoptimierung"}</span>
          <span className="about__tag">{"Adobe Creative Suite"}</span>
          <span className="about__tag">{"Responsive Design"}</span>
          <span className="about__tag">{"HTML"}</span>
          <span className="about__tag">{"Agiles Arbeiten"}</span>
          <span className="about__tag">{"Recruiting"}</span>
          <span className="about__tag">{"Mentoring"}</span>
          <span className="about__tag">{"CSS"}</span>
          <span className="about__tag">{"Visual Design"}</span>
        </div>
        <div className="about__learnings">
          <H2 className="about__skill-headline">{"Learning"}</H2>
          <span className="about__tag about__tag--secondary">
            {"User-Interviews"}
          </span>
          <span className="about__tag about__tag--secondary">{"Figma"}</span>
          <span className="about__tag about__tag--secondary">
            {"UX Writing"}
          </span>
        </div>
      </Section>
      <Section>
        <H2>Qualifikationen & Zertifikate</H2>
        <ul className="about__certificates">
          <li className="about__certificate">
            <H3>{"Usability & UX Experte"}</H3>
            <P className="about__date">{"März - Juli 2021"}</P>
          </li>
          <li className="about__certificate">
            <H3>
              {
                "Certified Professional for Usability and User Experience (CPUX-F)"
              }
            </H3>
            <P className="about__date">{"Juni 2021"}</P>
          </li>
          <li className="about__certificate">
            <H3>{"User Experience Design Essentials Course"}</H3>
            <P className="about__date">{"Februar 2021"}</P>
          </li>
          <li className="about__certificate">
            <H3>
              {"Vom Mitarbeiter zur Führungskraft Seminar (inkl. Zertifikat)"}
            </H3>
            <P className="about__date">{"Juli 2020"}</P>
          </li>
          <li className="about__certificate">
            <H3>{"GTD Fundamentals Seminar"}</H3>
            <P className="about__date">{"Februar 2020"}</P>
          </li>
          <li className="about__certificate">
            <H3>{"Ausbilderschein (AEVO) - Handelskammer Hamburg"}</H3>
            <P className="about__date">{"Oktober 2017"}</P>
          </li>
          <li className="about__certificate">
            <H3>{"Ausbildung zur Mediengestalterin Digital & Print"}</H3>
            <P className="about__date">{"August 2014 - Juli 2016"}</P>
          </li>
        </ul>
      </Section>
    </>
  );
};
