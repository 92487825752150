import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Link } from "../link";
import { P } from "../paragraph";

import "./Footer.css";

export const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    const { hash } = window.location;

    if (hash !== "") {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <footer className="footer">
      <section className="footer__inner">
        <P>
          {`© ${new Date().getFullYear()} Samira Stein | `}
          <Link to="/legal">Impressum</Link>
        </P>
        <nav>
          <Link
            href="mailto:hallo@samira-stein.de"
            label="E-Mail"
            className="footer__link"
          >
            <svg
              className="footer__icon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M20.016 8.016v-2.016l-8.016 5.016-8.016-5.016v2.016l8.016 4.969zM20.016 3.984q0.797 0 1.383 0.609t0.586 1.406v12q0 0.797-0.586 1.406t-1.383 0.609h-16.031q-0.797 0-1.383-0.609t-0.586-1.406v-12q0-0.797 0.586-1.406t1.383-0.609h16.031z"></path>
            </svg>
          </Link>
          <Link
            href="https://dribbble.com/Esclaire"
            label="dribbble"
            className="footer__link"
          >
            <svg
              className="footer__icon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12 24c-6.619 0-12-5.381-12-12s5.381-12 12-12c6.619 0 12 5.381 12 12s-5.381 12-12 12v0zM22.12 13.641c-0.352-0.112-3.173-0.952-6.384-0.436 1.341 3.684 1.884 6.684 1.992 7.308 2.297-1.556 3.938-4.017 4.392-6.872v0zM16.003 21.45c-0.155-0.9-0.745-4.031-2.184-7.772-0.023 0.009-0.047 0.014-0.066 0.023-5.784 2.016-7.861 6.023-8.044 6.398 1.739 1.355 3.923 2.166 6.291 2.166 1.42 0 2.775-0.291 4.003-0.816v0zM4.383 18.867c0.234-0.398 3.047-5.053 8.33-6.764 0.131-0.042 0.267-0.084 0.403-0.122-0.258-0.581-0.539-1.167-0.83-1.739-5.114 1.533-10.083 1.467-10.533 1.462-0.005 0.103-0.005 0.206-0.005 0.314 0 2.63 0.998 5.034 2.634 6.848v0zM1.969 9.914c0.459 0.005 4.683 0.023 9.478-1.247-1.697-3.019-3.53-5.559-3.802-5.93-2.869 1.355-5.016 3.998-5.677 7.177v0zM9.6 2.048c0.281 0.38 2.147 2.916 3.825 6 3.647-1.364 5.189-3.441 5.372-3.703-1.809-1.608-4.191-2.583-6.797-2.583-0.825 0.005-1.631 0.103-2.4 0.286v0zM19.936 5.536c-0.216 0.291-1.936 2.494-5.723 4.041 0.239 0.487 0.469 0.984 0.68 1.486 0.075 0.178 0.15 0.352 0.22 0.53 3.413-0.427 6.802 0.258 7.139 0.328-0.023-2.419-0.891-4.641-2.316-6.384v0z"></path>
            </svg>
          </Link>
          <Link
            href="https://www.linkedin.com/in/samira-stein-809755104/"
            className="footer__link"
            label="LinkedIn"
          >
            <svg
              className="footer__icon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M21.75 0h-19.5c-1.237 0-2.25 1.013-2.25 2.25v19.5c0 1.237 1.013 2.25 2.25 2.25h19.5c1.237 0 2.25-1.013 2.25-2.25v-19.5c0-1.237-1.013-2.25-2.25-2.25zM9 19.5h-3v-10.5h3v10.5zM7.5 7.5c-0.83 0-1.5-0.67-1.5-1.5s0.67-1.5 1.5-1.5c0.83 0 1.5 0.67 1.5 1.5s-0.67 1.5-1.5 1.5zM19.5 19.5h-3v-6c0-0.83-0.67-1.5-1.5-1.5s-1.5 0.67-1.5 1.5v6h-3v-10.5h3v1.861c0.619-0.848 1.566-1.861 2.625-1.861 1.866 0 3.375 1.678 3.375 3.75v6.75z"></path>
            </svg>
          </Link>
          <Link
            href="https://www.xing.com/profile/Samira_Stein2/cv"
            className="footer__link"
            label="XING"
          >
            <svg
              className="footer__icon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M21.75 0h-19.5c-1.237 0-2.25 1.013-2.25 2.25v19.5c0 1.237 1.013 2.25 2.25 2.25h19.5c1.237 0 2.25-1.013 2.25-2.25v-19.5c0-1.237-1.013-2.25-2.25-2.25zM7.327 15.609h-2.592c-0.155 0-0.272-0.070-0.338-0.178-0.070-0.112-0.070-0.253 0-0.398l2.756-4.866c0.005-0.005 0.005-0.009 0-0.014l-1.753-3.038c-0.070-0.145-0.084-0.286-0.014-0.398 0.066-0.108 0.197-0.164 0.356-0.164h2.597c0.398 0 0.595 0.258 0.722 0.488 0 0 1.772 3.094 1.786 3.113-0.103 0.188-2.803 4.955-2.803 4.955-0.141 0.244-0.328 0.502-0.717 0.502zM19.603 3.567l-5.747 10.162c-0.005 0.005-0.005 0.014 0 0.019l3.661 6.684c0.070 0.145 0.075 0.291 0.005 0.403-0.066 0.108-0.188 0.164-0.347 0.164h-2.592c-0.398 0-0.595-0.263-0.727-0.492 0 0-3.68-6.75-3.689-6.769 0.183-0.323 5.775-10.242 5.775-10.242 0.141-0.248 0.309-0.492 0.694-0.492h2.63c0.155 0 0.281 0.061 0.347 0.164 0.066 0.108 0.066 0.253-0.009 0.398z"></path>
            </svg>
          </Link>
        </nav>
      </section>
    </footer>
  );
};
