import { H1, H2, H3 } from "../../components/headline";
import { P } from "../../components/paragraph";
import { Section } from "../../components/section";
import { Dataset } from "../../components/dataset";
import { Gallery } from "../../components/gallery/Gallery";
import { Link } from "../../components/link";
import { ProjectSwitch } from "../../components/projectswitch/ProjectSwitch";

import "./Bootcamp.css";
import intro from "./assets/intro.png";
import introx2 from "./assets/introx2.png";
import userneeds from "./assets/userneeds.jpg";
import userneedsx2 from "./assets/userneedsx2.jpg";
import KJ from "./assets/KJ.jpg";
import KJx2 from "./assets/KJx2.jpg";
import taskflow from "./assets/taskflow.jpg";
import taskflowx2 from "./assets/taskflowx2.jpg";

import journeys from "./assets/journeys.jpg";
import journeysx2 from "./assets/journeysx2.jpg";

import scribble from "./assets/scribble.png";
import scribblex2 from "./assets/scribblex2.png";
import lofi from "./assets/lofi.png";
import lofix2 from "./assets/lofix2.png";
import hifi from "./assets/hifi.png";
import hifix2 from "./assets/hifix2.png";
import arrow from "./assets/arrow.svg";

import result2 from "./assets/result2.png";
import result2x2 from "./assets/result2x2.png";
import result3 from "./assets/result3.png";
import result3x2 from "./assets/result3x2.png";

import nina from "./assets/ninax2.jpg";
import mustafa from "./assets/mustafax2.jpg";
import max from "./assets/maxx2.jpg";
import brigitte from "./assets/brigittex2.jpg";
import luigi from "./assets/luigix2.jpg";

import { Define } from "./assets/define";
import { Prototype } from "./assets/prototype";
import { Research } from "./assets/research";
import { Test } from "./assets/test";
import { People } from "./assets/people";

export const Bootcamp = () => {
  return (
    <>
      <Section reducedWidth>
        <H2>Case Study</H2>
        <H1>
          <span className="bootcamp--highlight">Lieferservice App</span>
          <br />
          Luigi’s Pizza
        </H1>
        <P>
          Projekt der Usability Academy zu einer Lieferservice-App, die mich
          durch den gesamten menschzentrierten Gestaltungsprozess geführt hat.
        </P>
      </Section>
      <Section>
        <Dataset
          data={[
            {
              title: "Das Team:",
              text: "3 Mentor:innen, 3 UX/UI Mentees (inkl. mir)",
            },
            { title: "Dauer:", text: "5 Wochen" },
            { title: "Tools:", text: "Miro, Axure" },
            {
              title: "Methoden & Techniken:",
              text: "Menschzentrierter Gestaltungsprozess",
            },
          ]}
        />
      </Section>
      <Section color="green" direction="right" image={intro} retina={introx2}>
        <H2>Kontext</H2>
        <P>
          Dieses Projekt war dafür konzipiert, alle Phasen des menschzentrierten
          Gestaltungsprozesses und einige Methoden und Tools kennenzulernen.
          Dabei gab es Feedback von Mentor:innen, den anderen Teilnehmenden und
          in Usability Tests.
        </P>
        <P>
          Die meisten Deliverables wurden von mir allein erstellt und
          anschließend in der Gruppe besprochen.
        </P>
        <P>
          Zu entwickeln war eine Lieferservice-App für “Luigis Pizza”, ein
          italienisches Restaurant, das für Qualität über Quantität steht.
        </P>
      </Section>
      <Section halfSpacing>
        <H2>Der menschzentrierte Gestaltungsprozess</H2>
        <div className="bootcamp__process-row">
          <div className="bootcamp__process-item">
            <Research />
            <P>Verstehen, Analyse und Spezifikation des Nutzungskontexts</P>
          </div>
        </div>
        <div className="bootcamp__process-row">
          <div className="bootcamp__process-item">
            <Test />
            <P>Evaluation des Designs gegen die Nutzungs anforderungen</P>
          </div>
          <People />
          <div className="bootcamp__process-item">
            <Define />
            <P>Spezifikation der Nutzungsanforderungen</P>
          </div>
        </div>
        <div className="bootcamp__process-row">
          <div className="bootcamp__process-item">
            <Prototype />
            <P>Gestaltungslösungen, um Nutzungsanforderungen zu erfüllen</P>
          </div>
        </div>
      </Section>
      <Section reducedWidth>
        <H2>Verstehen, Analyse und Spezifikation des Nutzungskontexts</H2>
        <P>
          Um uns einen Überblick über die Nutzer:innen von Pizza-Lieferdiensten
          zu machen und mentale Modelle zu identifizieren, haben wir Interviews,
          unmoderierte Nutzer:innentest mit der Konkurrenz Smileys durchgeführt
          und Statistiken ausgewertet.
        </P>
        <P>
          Mit den Interviews haben wir herausgearbeitet, wie und in welcher
          Umgebung die App benutzt wird, welche Geräte dafür genutzt werden und
          was die Kriterien für die Auswahl des Lieferservices sind.
        </P>
      </Section>
      <Section direction="left">
        <H3>Ist-Szenario</H3>
        <P>
          Nachdem jede:r von uns jeweils 3 Nutzer:innen aus unserem Umfeld
          interviewt hat, haben wir die Ergebnisse zusammengetragen und einzeln
          für jede:n ein Ist-Szenario erstellt.
        </P>
        <P>
          Dabei bin ich darauf eingegangen, in welcher Umgebung, mit welchen
          Gedanken, Gefühlen und welchen Hindernissen und Ärgernissen ein
          potenzieller Nutzer oder eine potenzielle Nutzerin zu tun hat.
        </P>
        <P>
          Mein Research hat ergeben, dass Menschen meist in Gesellschaft
          bestellen und der Prozess nicht zu lang dauern darf, damit genug Zeit
          für Freunde und Familie bleibt. Sie bestellen gerne Bekanntes und
          probieren bei Lieferdiensten selten Neues aus.
        </P>
      </Section>
      <Section reducedWidth>
        <H3>Personas</H3>
        <P>
          Unsere Benutzergruppen waren sehr divers. Die Proto-Persona, die wir
          aus einer offiziellen Statistik über den typischen Lieferservicenutzer
          entwickelt haben, hat sich stark von unseren Interviewees
          unterschieden. Dies hat uns verdeutlicht, dass viele unterschiedliche
          Menschen heutzutage Pizza-Lieferservices in Anspruch nehmen.
        </P>
        <P>
          Die restlichen Personas habe ich aus den Interviews und den zuvor
          ermittelten Benutzergruppen erstellt.
        </P>
        <P>
          Wir wollen unseren Nutzer:innen ermöglichen, ihre Aufgabe so schnell
          wie möglich mit so wenig kognitiver Belastung wie nötig zu erledigen.
          Das bedeutet, dass sie keinen Gedanken an das “Wie” verschwenden
          sollen, sondern nur an das “Was”.
        </P>
      </Section>
      <Section>
        <Gallery
          images={[
            { src: mustafa, name: "Mustafa" },
            { src: brigitte, name: "Brigitte" },
            { src: nina, name: "Nina" },
            { src: luigi, name: "Luigi" },
            { src: max, name: "Max" },
          ]}
        />
      </Section>
      <Section direction="left" image={taskflow} retina={taskflowx2}>
        <H3>Aufgabenmodelle</H3>
        <P>
          Wir haben gemeinsam im Team jeweils für die Kund:innen und das
          Personal ein Aufgabenmodell erstellt. Dabei haben wir
          herausgearbeitet, wo Entscheidungen getroffen werden und potenzielle
          Absprünge aus der App passieren könnten.
        </P>
      </Section>
      <Section reducedWidth>
        <H3>User Journey Maps</H3>
        <P>
          Für jede Persona habe ich jeweils eine User Journey Map erstellt.
          Fokus lag dabei auf den Gedanken und Gefühlen meiner Persona, welche
          Aktionen sie im Prozess ausführt und welche Chancen unsere App bieten
          kann, sie bei ihrer Aufgabenerledigung zu unterstützen.
        </P>
      </Section>
      <Section>
        <div className="bootcamp__journey__names">
          <P>Mustafa</P>
          <P>Brigitte</P>
          <P>Nina</P>
        </div>
        <img
          src={journeys}
          srcset={(journeys, journeysx2)}
          alt=""
          className="bootcamp__journeys"
        />
      </Section>
      <Section reducedWidth>
        <H2>Spezifikation der Nutzungsanforderungen</H2>
        <P>
          Um langsam vom Problemraum in den Lösungsraum zu kommen, habe ich
          zunächst die Erkenntnisse aus dem Research in Bedürfnisse der
          Nutzer:innen umgewandelt. Im darauffolgenden Schritt ging es dann
          darum, Nutzungsanforderungen an unsere App zu definieren.
        </P>
      </Section>
      <Section reducedWidth>
        <H3>Erfordernisse</H3>
        <P>
          Ich habe die Erfordernisse sowohl für unsere Kundschaft (primäre
          Nutzer:innen), als auch das Personal (sekundäre Nutzer:innen)
          spezifiziert.
        </P>
        <P>
          Dabei habe ich mir die User Journey Maps angesehen und habe aus den
          einzelnen Schritten die Erfordernisse der Nutzer:innen extrahiert.
          <br /> Diese habe ich in Miro gesammelt.
        </P>
      </Section>
      <Section direction="right" image={userneeds} retina={userneedsx2}>
        <H3>Anforderungen</H3>
        <P>
          Die Bedürfnisse mussten nun in konkrete Anforderungen an unsere App
          umgewandelt werden. Dafür habe ich eine Tabelle angelegt, in der ich
          jedem Bedürfnis ein oder mehrere Nutzungsanforderungen an die App
          gegenübergestellt habe.
        </P>
        <P>
          Außerdem habe ich noch Marktanforderungen und organisatorische
          Anforderungen definiert. Diese wurden uns zum Teil von unseren
          Mentor:innen als unsere Stakeholder vorgegeben.
        </P>
      </Section>
      <Section color="anthrazit" direction="center">
        <H2>Die Challenge</H2>
        <P>
          Unsere Lieferdienst-App muss die Bedürfnisse von sehr
          unterschiedlichen Nutzer:innen erfüllen und den hohen Standard der
          Produkte und Marke Luigis repräsentieren. Gleichzeitig soll sie
          technisch dem gewohnten Standard angepasst sein und so die kognitive
          Belastung reduzieren.
        </P>
      </Section>
      <Section reducedWidth>
        <H2>Gestaltungslösungen, um Nutzungsanforderungen zu erfüllen</H2>
        <P>
          Bevor es zu den Low Fidelity und High Fidelity Prototypen ging, habe
          ich das Nutzungsszenario für meine App definiert.
        </P>
      </Section>
      <Section>
        <H3>Nutzungsszenario</H3>
        <P>
          Dieses wird auch Soll-Szenario genannt und legt den Fokus darauf zu
          verdeutlichen, wie unsere Kund:innen die App nutzen werden und welche
          Probleme sie löst.
        </P>
        <P>
          Bei meinem Nutzungsszenario habe ich besonderen Wert auf eine simple
          Nutzungsführung und personalisierte Filter gelegt.
        </P>
      </Section>
      <Section reducedWidth>
        <H2>Prototypen</H2>
        <P>
          Für unsere Prototypen habe ich zunächst auf einem weißen Blatt Papier
          begonnen und diesen meinen Teammitgliedern testen lassen. Nachdem ich
          dort das Feedback eingesammelt habe, habe ich basierend darauf erste
          Wireframes erstellt.
        </P>
        <P>
          Diese habe ich direkt in Miro gebaut und mit meinen Teammitgliedern
          weiter iteriert.
        </P>
        <div className="bootcamp__result">
          <img
            src={scribble}
            srcset={(scribble, scribblex2)}
            className="bootcamp__result__step"
            alt=""
          />
          <img src={arrow} className="bootcamp_arrow" alt="" />
          <img
            src={lofi}
            srcset={(lofi, lofix2)}
            className="bootcamp__result__step"
            alt=""
          />
          <img src={arrow} className="bootcamp_arrow" alt="" />
          <img
            src={hifi}
            srcset={(hifi, hifix2)}
            className="bootcamp__result__step"
            alt=""
          />
        </div>
      </Section>
      <Section reducedWidth>
        <H2>Evaluation des Designs gegen die Nutzungsanforderungen</H2>
        <P>
          Im letzten Schritt des menschzentrierten Gestaltungsprozesses soll das
          entwickelte Design getestet werden. Dafür haben wir einen
          Usability-Test gegenseitig und mit unseren Mentor:innen und eine
          heuristische Evaluation durchgeführt.
        </P>
        <P>
          Das Feedback der Usability-Tests haben wir in Miro gesammelt und dann
          eine zweite Version des Prototyps erstellt.
        </P>
        <P>
          Da die Zeit nicht für einen eigenen High-Fidelity-Prototypen gereicht
          hat, haben wir die heuristische Evaluation mit der Webseite Smileys
          durchgeführt und unsere Befunde vorgestellt und in der Gruppe
          diskutiert.
        </P>
      </Section>
      <Section direction="left" image={KJ} retina={KJx2}>
        <H3>KJ-Workshop</H3>
        <P>
          Im letzten Schritt haben wir einen KJ-Workshop durchgeführt. Dabei
          haben wir unsere Befunde in Cluster sortiert und diese benannt.
        </P>
        <P>
          Jede:r von uns hatte eine bestimmte Anzahl an Punkten, die man dem
          Befund zuweisen und somit in seiner Dringlichkeit und Schwere bewerten
          konnte. Ziel war es, gemeinsam die drei kritischsten Befunde
          herauszufiltern und bis zum nächsten Usability-Test eine neue Lösung
          zu erarbeiten.
        </P>
      </Section>
      <Section color="anthrazit" direction="center">
        <H2>Ergebnis</H2>
        <P>
          Zu sehen sind drei Beispiele des Low-Fidelity Prototypen nach mehreren
          Iterationen.
        </P>
        <div className="bootcamp__result">
          <img
            src={hifi}
            srcset={(hifi, hifix2)}
            className="bootcamp__result__step"
            alt=""
          />
          <img src={arrow} className="bootcamp_arrow" alt="" />
          <img
            src={result2}
            srcset={(result2, result2x2)}
            className="bootcamp__result__step"
            alt=""
          />
          <img src={arrow} className="bootcamp_arrow" alt="" />
          <img
            src={result3}
            srcset={(result3, result3x2)}
            className="bootcamp__result__step"
            alt=""
          />
        </div>
      </Section>
      <Section>
        <H2>Mehr Details? Let's talk!</H2>
        <div className="home__contact">
          <svg
            className="home__contact-mail"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 11.016l8.016-5.016h-16.031zM20.016 18v-9.984l-8.016 4.969-8.016-4.969v9.984h16.031zM20.016 3.984q0.797 0 1.383 0.609t0.586 1.406v12q0 0.797-0.586 1.406t-1.383 0.609h-16.031q-0.797 0-1.383-0.609t-0.586-1.406v-12q0-0.797 0.586-1.406t1.383-0.609h16.031z"></path>
          </svg>
          <div>
            <P>
              Mehr zu meinen Projekten und Vorgehensweisen erzähle ich gerne
              persönlich! <br />
              Egal ob Video-Call, leger bei einem ersten Kaffee, oder ganz
              formell im Meetingraum: <br /> Ich freue mich, von dir zu hören
              und dich kennenzulernen!
            </P>

            <Link className="home__email" href="mailto:hallo@samira-stein.de">
              <svg
                className="home__email-icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z"></path>
              </svg>
              hallo@samira-stein.de
            </Link>
          </div>
        </div>
      </Section>
      <ProjectSwitch previous="/booky-share" next="/booky-membership" />
    </>
  );
};
