import React from "react";

import "./List.css";

export const List = ({ children }) => {
  return <ul className="list">{children}</ul>;
};

export const ListItem = ({ children }) => {
  return <li className="list-item">{children}</li>;
};
