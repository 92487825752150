import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Dataset.css";

export class Dataset extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
  };

  render() {
    const { className, data } = this.props;

    return (
      <ol className={classNames("dataset", className)}>
        {data.map(({ title, text }) => (
          <li key={title} className="dataset__item">
            <b className="dataset__title">{title}</b>
            {text}
          </li>
        ))}
      </ol>
    );
  }
}
