import { H1, H2, H3, H4 } from "../../components/headline";
import { P } from "../../components/paragraph";
import { Section } from "../../components/section";
import { Dataset } from "../../components/dataset";
import { Process } from "../../components/process";
import { Link } from "../../components/link";
import { Video } from "../../components/video";
import { List, ListItem } from "../../components/list";
import { ProjectSwitch } from "../../components/projectswitch/ProjectSwitch";

import "./Membership.css";
import cancellation_final from "./assets/cancellation_video.mp4";
import membership_final from "./assets/membership_funnel.mp4";
import membershipMobile from "./assets/membership_funnel_mobile.mp4";
import intro from "./assets/membership_intro.png";
import befund1 from "./assets/finding_1_2.png";
import befund1_small from "./assets/findingx0.5.jpg";
import befund2 from "./assets/finding_3.png";
import befund2_small from "./assets/finding2x0.5.jpg";
import competitor from "./assets/konkurrenz.jpg";
import competitor2 from "./assets/konkurrenzx2.jpg";
import thomas from "./assets/thomas.jpg";
import thomas2 from "./assets/thomasx2.jpg";
import zou from "./assets/zou.jpg";
import zou2 from "./assets/zoux2.jpg";
import spotify from "./assets/spotify.jpg";
import spotify2 from "./assets/spotifyx2.jpg";
import statistics_age from "./assets/statistics_age.png";
import statistics_country from "./assets/statistics_country.png";
import statistics_gender from "./assets/statistics_gender.png";
import statistics_usage from "./assets/statistics_usage.png";
import { Define } from "./assets/Define";
import { Ideate } from "./assets/Ideate";
import { Test } from "./assets/Test";
import { Prototype } from "./assets/Prototype";
import { Research } from "./assets/Research";

export const Membership = () => {
  return (
    <>
      <Section reducedWidth>
        <H2>booky.io</H2>
        <H1>
          <span className="membership-headline--highlight">
            Neue Mitgliedschaft
          </span>
          , <br /> Kauf- und <br /> Kündigungsprozess
        </H1>
        <P>
          Entwicklung einer neuen kostenpflichtigen Mitgliedschaft und dem
          dazugehörigen Kauf- und Kündigungsprozesses, sowie Aufbau der
          visuellen Marke booky.io.
        </P>
      </Section>
      <Section>
        <Dataset
          data={[
            {
              title: "Das Team:",
              text: "1 Frontend-Entwickler, 1 Backend-Entwickler, 1 UX/UI Designerin (ich)",
            },
            { title: "Dauer:", text: "6 Wochen" },
            { title: "Tools:", text: "Miro, adobe XD" },
            {
              title: "Methoden & Techniken:",
              text: "Brand design, Research, Ideation, Prototyping, Usability Testing, UX Writing",
            },
          ]}
        />
      </Section>
      <Section color="blue" intro direction="left" image={intro} retina={intro}>
        <H2>Kontext</H2>
        <P>
          <Link href="https://www.booky.io" target="_blank">
            booky.io
          </Link>{" "}
          ist eine 2014 erstellte Webseite, um Lesezeichen geräte- und
          browserübergreifend speichern und organisieren zu können.
        </P>
        <P>
          Bislang haben zwei Entwickler an der Seite gearbeitet - der Gründer,
          der zugleich auch das bisherige Design und das Frontend entwickelt hat
          und ein Backend-Entwickler. Die Seite war komplett kostenlos. Das
          sollte nun geändert werden.
        </P>
        <P>
          Mein Auftrag war es, eine neue Mitgliedschaftsseite und den Kauf- und
          Kündigungsprozess zu bauen.
        </P>
      </Section>
      <Section reducedWidth>
        <H2>Brand Design</H2>
        <P>
          Bevor ich mit der eigentlichen Analyse und dem Feature anfangen
          konnte, habe ich in Brainstorming-Sessions zusammen mit dem Gründer
          die Marke “booky” ausgearbeitet.
        </P>
        <P>
          Dies diente als Grundlage für Ideen, wie eine passende Mitgliedschaft
          bei booky aussehen kann und wie die Kommunikation mit den Nutzer:innen
          erfolgen wird.
        </P>
        <Dataset
          data={[
            {
              title: "Persönlichkeit:",
              text: "Transparent, ehrlich, direkt",
            },
            { title: "Tone of Voice:", text: "Vertraut, freundlich, sachlich" },
            {
              title: "Unique Selling Points:",
              text: "Flexible Anpassbarkeit, einfache Handhabung, persönlicher Support",
            },
          ]}
        />
      </Section>
      <Section>
        <H2>Designprozess</H2>
        <Process
          data={[
            { icon: <Research />, text: "Research" },
            {
              icon: <Define />,
              text: "Empathize & Define",
            },
            { icon: <Ideate />, text: "Ideate" },
            { icon: <Prototype />, text: "Prototype" },
            {
              icon: <Test />,
              text: "Test",
            },
          ]}
        />
      </Section>
      <Section reducedWidth>
        <H2>Research</H2>
        <P>
          Um die booky Nutzer:innen kennenzulernen und mich mit den Konkurrenten
          und Mitgliedschaften bekanntzumachen, habe ich unterschiedliche
          Methoden angewandt.
        </P>
      </Section>
      <Section>
        <H3>Quantitative Umfrage</H3>
        <P>
          Zunächst wollte ich verstehen, wer die “typischen” booky Nutzer:innen
          sind.
          <br />
          In einer knappen Woche haben wir insgesamt 450 Antworten bekommen. Die
          Antworten aus der Umfrage haben wir noch um Daten aus Google
          Analytics, einem Nutzerinterview und internen Daten ergänzt.
        </P>
        <div className="membership__statistics">
          <img
            loading="lazy"
            src={statistics_gender}
            alt="Statistik zum Geschlecht: 81.5% männlich, 14.6% weiblich, 1.2% divers, 2.7% keine Angabe"
          />
          <img
            loading="lazy"
            src={statistics_age}
            alt="Statistik zum Alter: 30.9% sind zwischen 46 und 55 Jahre, 21.7% sind zwischen 36-45 Jahre, 18.5% über 56 Jahre, 17.3% zwischen 26 und 35 Jahre und 11.6% unter 25 Jahre alt."
          />
          <img
            loading="lazy"
            src={statistics_country}
            alt="Statistik zum Ort: 23% aus den USA, 8.6% aus Japan, 6.4% aus Indien, 6.1% aus Großbritannien und 5.1% aus Deutschland"
          />
          <img
            loading="lazy"
            src={statistics_usage}
            alt="Statistik zur Benutzung von booky: 59.3% nutzen es privat, 3.7% business und 37% für beide Fälle"
          />
        </div>
        <div className="membership__numbers">
          <div className="membership__numbers-wrapper">
            <P className="membership__number membership__number--one">
              {"< 10"} Sek.
            </P>
            <P>Aufenthaltszeit der Nutzer:innen pro Session</P>
          </div>
          <div className="membership__numbers-wrapper">
            <P className="membership__number membership__number--two">44,7%</P>
            <P>Arbeiten in der Software - oder Digitalbranche</P>
          </div>
          <div className="membership__numbers-wrapper">
            <P className="membership__number membership__number--three">
              4.6/5
            </P>
            <P>Zufriedenheit mit booky</P>
          </div>
        </div>
        <H4>Key Learnings:</H4>
        <List>
          <ListItem>
            Unsere Hauptbenutzergruppe ist technisch affin aber keine 'digital
            natives'
          </ListItem>
          <ListItem>
            Die Zeit, die Nutzer:innen auf booky verbringen, ist sehr kurz
          </ListItem>
          <ListItem>
            Die Gestaltung muss sowohl für westliche, als auch asiatische
            Kulturen angemessen sein
          </ListItem>
        </List>
      </Section>
      <Section direction="left" image={competitor} retina={competitor2}>
        <H3>Konkurrenzanalyse</H3>
        <P>
          Hierzu habe ich mir die Hauptkonkurrenten raindrop.io, linkish.io und
          bkmark.io angesehen.
        </P>
        <P>
          Hauptaugenmerk lag darauf, diese in ihren angebotenen Features,
          Businessmodellen, ihrer Aufmachung und Tone of Voice zu identifizieren
          und Differenzen zu booky herauszuarbeiten.
        </P>
        <H4>Ergebnis:</H4>
        <P>
          Die meisten anderen Lesezeichen-Manager bieten mehr Funktionen an als
          booky, limitieren ihre kostenfreie Mitgliedschaft allerdings auch
          stärker.
        </P>
        <P>
          Booky kann sich mit seinem effizienten Design, gutem Kundensupport und
          Nähe zu den Nutzer:innen am deutlichsten abheben. Diese Elemente
          sollten meine Designentscheidungen maßgeblich beeinflussen.
        </P>
      </Section>
      <Section reducedWidth>
        <H2>Empathise & Define</H2>
        <P>
          Aus den gewonnen Insights habe ich unterschiedliche Methoden
          ausgewählt, um mich in die Rolle der Nutzer:innen hineinzuversetzen
          und darauf basierend erste Scribbles erstellen zu können.
        </P>
      </Section>
      <Section noMargin>
        <H3>Personas</H3>
      </Section>
      <Section direction="right" image={thomas} retina={thomas2}>
        <P>
          Die Analyse der Daten ergab, dass unsere Hauptzielgruppe männlich,
          zwischen 45-55 Jahre alt ist und aus westlichen Ländern kommt. Thomas
          nutzt booky sowohl privat als auch geschäftlich und ist auf dem
          Desktop PC unterwegs. Beruflich arbeitet er in der Digitalbranche und
          hat einen Job in der IT. Damit ist er technisch affin, auf
          Datensicherheit bedacht und finanziell gut aufgestellt.
        </P>
      </Section>
      <Section direction="left" image={zou} retina={zou2}>
        <P>
          Unsere zweite Zielgruppe kommt aus den asiatischen Ländern, ist
          weiblich und zwischen 18-25 Jahren alt und arbeitet in der
          Designbranche oder befindet sich noch im Studium. Als “digital native”
          mit dem Smartphone aufgewachsen, ist Zou auch technisch affin,
          konsumiert viel online und ist es gewohnt, für Services wenig bis kein
          Geld auszugeben. Booky verwendet sie überwiegend privat.
        </P>
      </Section>
      <Section direction="left" reducedWidth>
        <H3>Business-Anforderungen</H3>
        <P>
          Booky war bislang komplett kostenlos und hat sehr auf die Freiheit der
          Nutzer:innen und deren Datensicherheit geachtet. Das Bezahlsystem muss
          diesem Standard entsprechen. Außerdem darf es keine versteckten Kosten
          oder komplizierte Kündigungsprozesse geben, da dies das Vertrauen der
          Nutzer:innen in die Marke schwächen könnte.
        </P>
        <P>
          Der Prozess sollte so kurz wie möglich und in allen Aspekten
          transparent gehalten werden, ohne Sternchentexte oder komplizierte
          Kündigungsverfahren. Die Nutzer:innen sind “wie du und ich”, booky ist
          nicht auf einmal das Unternehmen, was Geld verdienen möchte, sondern
          immer noch der sympathische Entwickler von nebenan.
        </P>
      </Section>
      <Section color="anthrazit" direction="center">
        <H2>Die Challenge</H2>
        <P>
          Trotz nur eines einzigen, exklusiven Features sollen die Nutzer:innen
          zum Abschluss eines Abonnements angeregt werden. Dafür muss auch der
          unterstützende Aspekt herausgestellt und kommuniziert werden. Der
          Prozess muss dabei der Persönlichkeit von booky treu bleiben und zur
          Marke passen.
        </P>
      </Section>
      <Section noMargin>
        <H2>Ideate</H2>
      </Section>
      <Section direction="right" image={spotify} retina={spotify2}>
        <P>
          Hier habe ich mir, neben meinen erhobenen Insights, die Konkurrenz und
          andere “global Player” als Inspiration angesehen.
          <br />
          <Link href="https://www.spotify.com/de/premium/" target="_blank">
            Spotify
          </Link>{" "}
          hat beispielsweise ein sehr reduziertes Design, das den Fokus auf die
          wesentlichen Inhalte lenkt und gut zu unserer Persona Thomas passt.
          Starke Farben, Illustrationen und schnell scannbare Inhalte sind für
          Persona Zou genauso passend.
        </P>
        <P>
          Ich habe mir auch ein paar indische und japanische Webseiten
          angeschaut. Diese haben sich im Wesentlichen nicht stark von unseren
          unterschieden. Die japanischen Webseiten waren manchmal etwas
          farbenfroher und verspielter.
        </P>
      </Section>
      <Section reducedWidth>
        <H2>Prototype</H2>
        <P>
          Die ersten Ideen habe ich auf Papier-Prototypen umgesetzt. Diese habe
          ich iterativ mit dem Gründer besprochen und Feedback eingearbeitet.
        </P>
        <P>
          Ich habe mich gegen das Testen des Low-Fidelity-Prototypen
          entschieden, da der user flow sehr geradlinig ist und ich die wenigen
          verfügbaren Nutzer:innen dafür nicht anfragen wollte. Die
          Schwierigkeit der Aufgabe sah ich darin, die Informationsarchitektur
          und Benefits auszuarbeiten.
        </P>
      </Section>
      <Section reducedWidth>
        <H2>Test</H2>
        <P>
          Unsere jeweils 5 Testteilnehmer:innen haben wir aus unserem Netzwerk
          rekrutiert.
        </P>
      </Section>
      <Section>
        <H3>Erste Iteration</H3>
        <P>
          Ich habe herausgefunden, dass alle Testteilnehmer:innen sehr schnell
          und erfolgreich ihre Aufgaben erledigen konnten. Allerdings sind sie
          über drei Dinge gestolpert.
        </P>
      </Section>
      <Section
        direction="left"
        image={befund1}
        retina={befund1}
        small={befund1_small}
      >
        <P>
          <strong>1.</strong> Die Teilen-Funktion wurde zunächst nicht entdeckt.
          Die Testteilnehmer:innen haben sie erst in den FAQs gefunden.
        </P>
        <P>
          <strong>Lösung:</strong> Ich habe eine Tabelle eingefügt, die einfach
          zu scannen ist und die Benefits der neuen Mitgliedschaft herausstellt.
        </P>
        <P>
          <strong>2.</strong> Der CTA wurde nur von der Hälfte der Leute sofort
          erkannt. Außerdem haben 3/5 Testteilnehmer:innen bis zum Seitenende
          gescrollt und dort nach dem CTA gesucht.
        </P>
        <P>
          <strong>Lösung:</strong> Copy des CTA prägnanter umformuliert und
          einen weiteren Button am unteren Ende der Seite hinzugefügt, sodass
          Nutzer:innen, die sich die gesamte Seite anschauen, nicht zum CTA
          hochscrollen müssen.
        </P>
      </Section>
      <Section
        direction="right"
        image={befund2}
        retina={befund2}
        small={befund2_small}
      >
        <P>
          <strong>3.</strong> Das Design der Fakten auf der Seite des
          Bezahlprozesses wurde als Buttons identifiziert und lenkte kurzfristig
          vom Bezahlen ab.
        </P>
        <P>
          <strong>Lösung:</strong> Die Fakten wurden um eine Überschrift ergänzt
          und grafisch zu einer Aufzählung umfunktioniert.
        </P>
      </Section>
      <Section reducedWidth>
        <H3>Zweite Iteration</H3>
        <P>
          Ich habe die Probleme aus der ersten Iteration nicht mehr beobachten
          können.
          <br />
          Generell gab es nur noch kleine Befunde, die nur bei einer einzigen
          Teilnehmerin bzw. einem einzigen Teilnehmer aufgetreten sind. Ich habe
          mich dagegen entschieden, diese abzuändern. Ein Befund von einem
          einzigen Test ist nicht aussagekräftig genug und kann im Zweifel sogar
          etwas verändern, was für die restlichen Testteilnehmer:innen gut
          funktioniert hat.
        </P>
      </Section>
      <Section reducedWidth>
        <H3>Fazit</H3>
        <P>
          Es war gut zu erkennen, dass die meisten Testteilnehmer:innen die
          Texte nur nach den wichtigen Informationen gescannt haben.
          <br />
          Die erste Variante hat nicht ausreichend herausgestellt, was die neue
          Mitgliedschaft von der regulären, kostenfreien Mitgliedschaft
          unterscheidet.
        </P>
        <P>
          Durch die Tabelle ist dies optisch nochmal einfacher zu erfassen
          gewesen. Auch das Wiederverwenden von Konventionen hat sich gelohnt.
          <br />
          So gab es keinen kritischen Befund beim Kündigungsprozess, dafür aber
          viele positive Kommentare zur Einfachheit und Verständlichkeit.
        </P>
      </Section>
      <Section>
        <H3>Learnings</H3>
        <List>
          <ListItem>
            Es kann sich lohnen, Ideen zu testen, bevor man sie verwirft
          </ListItem>
          <ListItem>
            Unmoderierte Tests müssen besonders gut ausgearbeitet sein
          </ListItem>
          <ListItem>
            User flows hätten mir noch einen besseren Einblick geben können
          </ListItem>
        </List>
      </Section>
      <Section color="anthrazit" direction="center">
        <H2>Das Ergebnis</H2>
        <H3>Support-Mitgliedschaft</H3>
        <Video src={membership_final} srcMobile={membershipMobile} />
        <P>Die Mitgliedschaftsseite und Abschluss des Abonnements.</P>
        <div className="membership__result">
          <H3>Kündigungsprozess</H3>
          <Video src={cancellation_final} />
          <P>Das Kündigen der Mitgliedschaft im Account-Bereich.</P>
        </div>
      </Section>
      <Section>
        <H2>Mehr Details? Let's talk!</H2>
        <div className="home__contact">
          <svg
            className="home__contact-mail"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 11.016l8.016-5.016h-16.031zM20.016 18v-9.984l-8.016 4.969-8.016-4.969v9.984h16.031zM20.016 3.984q0.797 0 1.383 0.609t0.586 1.406v12q0 0.797-0.586 1.406t-1.383 0.609h-16.031q-0.797 0-1.383-0.609t-0.586-1.406v-12q0-0.797 0.586-1.406t1.383-0.609h16.031z"></path>
          </svg>
          <div>
            <P>
              Mehr zu meinen Projekten und Vorgehensweisen erzähle ich gerne
              persönlich! <br />
              Egal ob Video-Call, leger bei einem ersten Kaffee, oder ganz
              formell im Meetingraum: <br />
              Ich freue mich, von dir zu hören und dich kennenzulernen!
            </P>

            <Link className="home__email" href="mailto:hallo@samira-stein.de">
              <svg
                className="home__email-icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z"></path>
              </svg>
              hallo@samira-stein.de
            </Link>
          </div>
        </div>
      </Section>
      <ProjectSwitch previous="/bootcamp" next="booky-share" />
    </>
  );
};
