export const Prototype = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M21 18v-8.016h-3.984v8.016h3.984zM21.984 8.016q0.375 0 0.703 0.305t0.328 0.68v9.984q0 0.375-0.328 0.703t-0.703 0.328h-6q-0.375 0-0.68-0.328t-0.305-0.703v-9.984q0-0.375 0.305-0.68t0.68-0.305h6zM11.016 17.484q0.609 0 1.055-0.445t0.445-1.055-0.445-1.055-1.055-0.445-1.055 0.445-0.445 1.055 0.445 1.055 1.055 0.445zM12.984 12v1.781q1.031 0.938 1.031 2.203 0 1.313-1.031 2.25v1.781h-3.984v-1.781q-0.984-0.891-0.984-2.25 0-1.313 0.984-2.203v-1.781h3.984zM3 6v12h3.984v2.016h-3.984q-0.797 0-1.406-0.609t-0.609-1.406v-12q0-0.797 0.609-1.406t1.406-0.609h18v2.016h-18z"></path>
  </svg>
);
