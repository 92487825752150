import { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { InView } from "react-intersection-observer";

import { Home } from "./pages/home";
import { About } from "./pages/about";
import { Contact } from "./pages/contact";
import { Membership } from "./pages/membership";
import { Share } from "./pages/share";
import { Bootcamp } from "./pages/bootcamp";
import { Legal } from "./pages/legal";
import { Miss } from "./pages/miss";
import { Footer } from "./components/footer";
import { Button } from "./components/button";

import "./App.css";

export const App = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleChange = (inView) => {
    setShowScrollButton(!inView);
  };

  return (
    <Router>
      {showScrollButton && (
        <Button
          type="primary"
          onClick={scrollTop}
          className="header__scroll-top"
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M3.984 12l8.016-8.016 8.016 8.016-1.453 1.406-5.578-5.578v12.188h-1.969v-12.188l-5.625 5.578z"></path>
          </svg>
        </Button>
      )}
      <InView onChange={handleChange} rootMargin="100%">
        {({ ref }) => (
          <header className="header" ref={ref}>
            <div className="header__inner">
              <Link to="/" className="logo">
                SamiraStein
              </Link>
              <nav className="nav">
                <NavLink
                  exact
                  to="/#work"
                  className="nav__link"
                  activeClassName="nav__link--active"
                >
                  Projekte
                </NavLink>
                <NavLink
                  to="/about"
                  className="nav__link"
                  activeClassName="nav__link--active"
                >
                  Über mich
                </NavLink>
                <NavLink
                  to="/contact"
                  className="nav__link"
                  activeClassName="nav__link--active"
                >
                  Kontakt
                </NavLink>
              </nav>
            </div>
          </header>
        )}
      </InView>
      <main>
        <Switch>
          <Route path="/about">{<About />}</Route>
          <Route path="/contact">{<Contact />}</Route>
          <Route path="/booky-membership">{<Membership />}</Route>
          <Route path="/booky-share">{<Share />}</Route>
          <Route path="/bootcamp">{<Bootcamp />}</Route>
          <Route path="/legal">{<Legal />}</Route>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route>
            <Miss />
          </Route>
        </Switch>
      </main>
      <Footer />
    </Router>
  );
};
