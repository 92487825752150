import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "../button";

import "./Gallery.css";

export class Gallery extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    images: PropTypes.array,
  };

  state = {
    activeItem: 0,
  };

  handleClickRight = () => {
    this.setState({
      activeItem:
        this.state.activeItem === this.props.images.length - 1
          ? 0
          : this.state.activeItem + 1,
    });
  };

  handleClickLeft = () => {
    this.setState({
      activeItem:
        this.state.activeItem === 0
          ? this.props.images.length - 1
          : this.state.activeItem - 1,
    });
  };

  render() {
    const { images, className } = this.props;
    const { activeItem } = this.state;

    return (
      <div className={classNames("gallery", className)}>
        <Button
          type="primary"
          onClick={this.handleClickLeft}
          className="gallery__button"
          label="Vorherige Persona"
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M20.016 11.016v1.969h-12.188l5.578 5.625-1.406 1.406-8.016-8.016 8.016-8.016 1.406 1.406-5.578 5.625h12.188z"></path>
          </svg>
        </Button>
        <div className="gallery__inner">
          <img
            src={images[activeItem].src}
            alt={`Persona ${images[activeItem].name}`}
            className="gallery__image"
          />
        </div>
        <Button
          type="primary"
          onClick={this.handleClickRight}
          className="gallery__button"
          label="Nächste Persona"
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z"></path>
          </svg>
        </Button>
      </div>
    );
  }
}
