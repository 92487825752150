import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Link.css";

import { Link as RouterLink } from "react-router-dom";

export class Link extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    href: PropTypes.string,
    to: PropTypes.string,
    target: PropTypes.string,
    label: PropTypes.string,
  };

  render() {
    const { className, children, href, to, target, label } = this.props;

    const CustomTag = href ? "a" : RouterLink;

    return (
      <CustomTag
        href={href}
        to={to}
        target={target}
        className={classNames("link", className)}
        aria-label={label}
        title={label}
      >
        {children}
      </CustomTag>
    );
  }
}
